import axios from "axios";
import Dexie from "dexie";

const db = new Dexie("AudioDatabase");
db.version(1).stores({
  audios: "++id, audioBlob, taskID",
});

const saveAudioLocally = async (audioBlob) => {
  try {
    await db.audios.add({ audioBlob, taskID: "" }); // taskID će biti null dok se ne generiše online
    console.log("Audio saved locally");
  } catch (error) {
    console.error("Error saving audio locally:", error);
  }
};

const generateTask = async (username) => {
  const response = await fetch(
    "https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/generateTask",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username }), // Pretpostavka da `username` dolazi kao argument funkcije
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }
  const data = await response.json();
  return data.taskID; // Možete vratiti i celokupan objekat `data` ako je potrebno
};

const uploadAudioToServer = async (audioBlob, taskID) => {
  const fileName = `audio_${taskID}.wav`; // Generiše ime fajla
  const uploadUrl = `https://uu6ha38ls9.execute-api.eu-central-1.amazonaws.com/Prod/upload/${fileName}`; // Ažuriran URL

  try {
    const response = await fetch(uploadUrl, {
      method: "PUT",
      body: audioBlob, // Direktno šaljemo audioBlob
      headers: {
        "Content-Type": "audio/wav", // Specifikujemo tip sadržaja
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    console.log("Audio uploaded successfully");
    // Obradite uspešan odgovor
  } catch (error) {
    console.error("Error uploading audio file:", error);
  }
};

const addToCollection = async (taskID, collectionID) => {
  try {
    await axios.post(
      "https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/manageTaskInCollection",
      {
        taskID: taskID,
        collectionID: collectionID,
        action: "add",
      }
    );
  } catch (error) {
    console.error("Error adding transcript to collection:", error);
    alert("Грешка при додавању транскрипта у колекцију");
  }
};
const synchronizeAudios = async (username, setIsSynchronizing) => {
  setIsSynchronizing(true);
  console.log("Pokušavam da sinhronizujem audio snimke...");
  const audios = await db.audios.where("taskID").equals("").toArray();

  if (audios.length === 0) {
    console.log("Nema audio snimaka za sinhronizaciju.");
    setIsSynchronizing(false);
    return; // Prekidamo funkciju ako nema šta da se sinhronizuje
  }

  for (const audio of audios) {
    try {
      console.log(`Generišem taskID za audio id=${audio.id}`);
      const taskID = await generateTask(username);
      console.log(`Uploadujem audio sa generisanim taskID=${taskID}`);
      await uploadAudioToServer(audio.audioBlob, taskID);
      await pollForStatusAndPostProcess(taskID);

      await db.audios.delete(audio.id);
      console.log(`Audio id=${audio.id} je sinhronizovan i obrisan lokalno`);
    } catch (error) {
      console.error("Greška prilikom sinhronizacije audio snimka:", error);
    }
  }
  setIsSynchronizing(false);
};

async function pollForStatusAndPostProcess(taskID) {
  let completed = false;
  do {
    try {
      const response = await fetch(
        `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/pollStatus/${taskID}`
      );
      const data = await response.json();
      if (data.Count > 0 && data.Items[0].taskStatus.S === "completed") {
        console.log(`Postprocesiranje za taskID ${taskID}`);
        await postProcessTranscript(taskID);
        completed = true;
      } else if (
        data.Count > 0 &&
        data.Items[0].taskStatus.S.includes("error")
      ) {
        console.error(`Greška pri obradi taskID ${taskID}`);
        completed = true; // Prekidamo polling ako postoji greška
      }
    } catch (error) {
      console.error("Greška prilikom pollinga statusa:", error);
      completed = true; // Prekidamo polling ako dođe do mrežne greške
    }
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Čekamo pre sledećeg pollinga
  } while (!completed);
}

const postProcessTranscript = async (taskID) => {
  try {
    const postProcessResponse = await fetch(
      `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/postProcess`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ taskID: taskID }), // Dodajemo GPT flag u body
      }
    );
    const data = postProcessResponse.data;
    console.log("Postprocessing response:", data);
    return data; // Povratni podaci od postprocesiranja, ako su potrebni
  } catch (error) {
    console.error("Error during postprocessing:", error);
    throw error; // Propagiranje greške za dalje rukovanje
  }
};

export {
  generateTask,
  uploadAudioToServer,
  addToCollection,
  synchronizeAudios,
  saveAudioLocally,
};
