import React, { useState, useEffect } from "react";
import axios from "axios";
import Papa from "papaparse";

function Epikriza({ username, selectedTranscriptText }) {
  const [epikriza, setEpikriza] = useState("");
  const [podaciPacijenta, setPodaciPacijenta] = useState("");
  const [epikrizaId, setEpikrizaId] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [patientDetails, setPatientDetails] = useState({});

  useEffect(() => {
    setPodaciPacijenta((prevText) =>
      prevText
        ? `${prevText}\n${selectedTranscriptText}`
        : selectedTranscriptText
    );
  }, [selectedTranscriptText]);

  useEffect(() => {
    if (selectedPatientId) {
      const patientData = patientDetails[selectedPatientId]
        ? patientDetails[selectedPatientId].map((d) => d.misljenje).join("\n")
        : "";
      setPodaciPacijenta(patientData);
    }
  }, [selectedPatientId, patientDetails]);

  useEffect(() => {
    let intervalId = null; // Local variable to hold interval ID

    const fetchEpikriza = async () => {
      if (epikrizaId) {
        try {
          const { data } = await axios.get(
            `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/getEpikrizaById/${epikrizaId}`
          );
          if (data && data.Item && data.Item.epikriza && data.Item.epikriza.S) {
            setEpikriza(data.Item.epikriza.S);
            clearInterval(intervalId); // Clear using the local variable
            setIsGenerating(false);
          }
        } catch (error) {
          console.error("Error fetching epikriza:", error);
        }
      }
    };

    if (epikrizaId && !intervalId) {
      intervalId = setInterval(fetchEpikriza, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Ensure the interval is cleared when component unmounts
      }
    };
  }, [epikrizaId]);

  const handleGenerateEpikriza = async () => {
    if (!podaciPacijenta.trim()) {
      alert("Ne možete generisati epikrizu bez podataka.");
      return;
    }
    setIsGenerating(true);

    try {
      const { data } = await axios.post(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/generateEpikriza`,
        {
          inputData: podaciPacijenta,
          username,
        }
      );
      setEpikrizaId(data.epikrizaID);
      console.log("Epikriza generation initiated successfully:", data);
    } catch (error) {
      console.error("Error generating epikriza:", error);
    }
  };

  const handlePostProcess = async () => {
    if (!epikriza || !epikrizaId || isProcessing) {
      alert("Prvo generišite epikrizu.");
      return;
    }
    setIsProcessing(true);

    try {
      const { data } = await axios.post(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/epikrizaPostProcess`,
        {
          epikrizaID: epikrizaId,
        }
      );
      setEpikriza(data.pp_epikriza);
      console.log("Epikriza postprocessed successfully:", data);
    } catch (error) {
      console.error("Error postprocessing epikriza:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (results) => {
        const details = results.data.reduce((acc, row) => {
          if (row.tip_pepi === "LED") {
            // Samo dodavanje mišljenja gde je tip_pepi jednak "LED"

            const id = row.id_pacijenta;
            if (!acc[id]) {
              acc[id] = [];
            }
            acc[id].push({ misljenje: row.misljenje, tip_pepi: row.tip_pepi });
          }
          return acc;
        }, {});

        setPatientDetails(details);
        setSelectedPatientId(Object.keys(details)[0] || "");
      },
      header: true,
    });
  };
  return (
    <div>
      <div className="button-container">
        <button
          onClick={handleGenerateEpikriza}
          className="amplify-button amplify-button--primary"
          disabled={isGenerating || !podaciPacijenta.trim()}>
          {isGenerating ? "Generisanje u toku..." : "Generiši Epikrizu"}
        </button>
        <div className="row">
          <label
            htmlFor="csvInput"
            className="amplify-button amplify-button--primary">
            <i className="fa-solid fa-file-csv"></i>
          </label>
          <input
            type="file"
            id="csvInput"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleCsvUpload}
          />
          <select
            value={selectedPatientId}
            className="select-input"
            style={{ width: "20%" }}
            onChange={(e) => setSelectedPatientId(e.target.value)}>
            <option value="">Izaberite pacijenta</option>
            {Object.keys(patientDetails).map((id) => (
              <option key={id} value={id}>
                {id}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="content-container">
        <div className="form-group left">
          <label htmlFor="podaciPacijentaBox">Извештај</label>
          <textarea
            id="podaciPacijentaBox"
            value={podaciPacijenta}
            onChange={(e) => setPodaciPacijenta(e.target.value)}
          />
        </div>
        <div className="icon-buttons">
          <button
            onClick={() => navigator.clipboard.writeText(epikriza)}
            disabled={!epikriza}
            className="amplify-button amplify-button--primary">
            <i className="fa-regular fa-copy"></i>
          </button>
          <button
            onClick={() => {
              setPodaciPacijenta("");
              setEpikriza("");
            }}
            className="amplify-button amplify-button--primary">
            <i className="fa-regular fa-trash-can"></i>
          </button>
        </div>
        <div className="form-group right">
          <label htmlFor="epikrizaBox">Епикриза</label>
          <textarea
            id="epikrizaBox"
            value={epikriza}
            onChange={(e) => setEpikriza(e.target.value)}
          />
        </div>
      </div>
      <div className="button-container">
        <button
          onClick={handlePostProcess}
          className="amplify-button amplify-button--primary"
          disabled={!epikriza || isProcessing}>
          {isProcessing ? "Uređivanje u toku..." : "Uredi Epikrizu"}
        </button>
      </div>
    </div>
  );
}

export default Epikriza;
