import React, { useState } from "react";
import axios from "axios";
import Modal from "./Modal";
import {
  downloadTranscript,
  bulkDownloadSelectedTranscripts,
} from "../services/transcriptService";
import AudioPlayer from "./AudioPlayer";

const TranscriptHistory = ({
  transcripts,
  onSelectTranscript,
  toggleSidebar,
  collections,
  updateTranscripts,
  refreshData,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [currentTranscriptId, setCurrentTranscriptId] = useState("");
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [currentTranscript, setCurrentTranscript] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [isAudioModalOpen, setIsAudioModalOpen] = useState(false);
  const [currentAudioUrl, setCurrentAudioUrl] = useState("");
  const [selectedTranscripts, setSelectedTranscripts] = useState(new Set());
  const [bulkDownloadModal, setBulkDownloadModal] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return "Nepoznato";
    const date = new Date(dateString);
    return date.toLocaleString("sr-RS", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleTranscriptSelection = (transcriptId, event) => {
    const newSelection = new Set(selectedTranscripts);
    if (newSelection.has(transcriptId)) {
      newSelection.delete(transcriptId);
    } else {
      newSelection.add(transcriptId);
    }
    setSelectedTranscripts(newSelection);
  };

  const toggleAllTranscriptsSelection = () => {
    if (selectedTranscripts.size !== 0) {
      setSelectedTranscripts(new Set());
    } else {
      const allTranscriptIds = new Set(transcripts.map((t) => t.taskID.S));
      setSelectedTranscripts(allTranscriptIds);
    }
  };

  const handleBulkDownload = () => {
    if (selectedTranscripts.size === 0) {
      alert("Молимо Вас да одаберете бар један транскрипт за преузимање.");
      return;
    }

    setBulkDownloadModal(true);
  };

  const downloadSelectedTranscripts = (mergeFiles) => {
    if (selectedFormat) {
      bulkDownloadSelectedTranscripts(
        transcripts,
        selectedTranscripts,
        selectedFormat,
        mergeFiles
      );
      setSelectedTranscripts(new Set());
    } else {
      alert("За преузимање неопходно је изабрати формат.");
    }
    setBulkDownloadModal(false);
  };

  const handleShowDownloadModal = (event, transcript) => {
    event.stopPropagation();
    setCurrentTranscript(transcript);
    setShowDownloadModal(true);
  };

  const filteredTranscripts = transcripts.filter(
    (transcript) =>
      transcript.postprocessed_transcript.S.toLowerCase().includes(
        searchTerm.toLowerCase()
      ) || formatDate(transcript.creationTime.S).includes(searchTerm)
  );

  const deleteTranscript = async (taskID, event) => {
    event.stopPropagation(); // Sprečava pokretanje onClick događaja za selektovanje transkripta

    const userConfirmation = window.confirm(
      "Да ли сте сигурни да желите да обришете транскрипт?"
    );
    if (!userConfirmation) {
      return;
    }

    try {
      await axios.put(
        `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/markAsDeleted/${taskID}`
      );
      const updatedTranscripts = transcripts.filter(
        (t) => t.taskID.S !== taskID
      );
      updateTranscripts(updatedTranscripts);
    } catch (error) {
      console.error("Error deleting transcript:", error);
    }
  };

  const handleAddToCollectionClick = (event, taskID) => {
    event.stopPropagation();
    setCurrentTranscriptId(taskID);
    setIsModalOpen(true);
  };

  const handleCollectionSelect = (event) => {
    setSelectedCollectionId(event.target.value);
  };

  const addToCollection = async () => {
    if (!selectedCollectionId || !currentTranscriptId) {
      alert("Молимо Вас да одаберете колекцију");
      return;
    }

    try {
      await axios.post(
        "https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/manageTaskInCollection",
        {
          taskID: currentTranscriptId,
          collectionID: selectedCollectionId,
          action: "add",
        }
      );
      setIsModalOpen(false);
      refreshData();
      alert("Транскрипт успешно додат у колекцију!");
    } catch (error) {
      console.error("Error adding transcript to collection:", error);
      alert("Error adding transcript to collection");
    }
  };

  const handlePlayAudio = async (event, taskID) => {
    event.stopPropagation();
    try {
      const response = await axios.get(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/getAudioSignedUrl?taskID=${taskID}`
      );
      setCurrentAudioUrl(response.data.audioFileUrl);
      setIsAudioModalOpen(true);
    } catch (error) {
      console.error("Error fetching audio URL:", error);
    }
  };

  return (
    <div className="transcript-history">
      <h2>Историја извештаја</h2>
      <input
        className="search-input"
        type="text"
        placeholder="Претражи по тексту или датуму..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className="transcript-history-controls">
        <button
          className="amplify-button amplify-button--primary"
          onClick={handleBulkDownload}>
          Преузми означене транскрипте
        </button>
        <button
          className="amplify-button amplify-button--secondary"
          onClick={toggleAllTranscriptsSelection}>
          {selectedTranscripts.size !== 0 ? (
            <i className="fa-regular fa-square-check"></i>
          ) : (
            <i className="fa-regular fa-square"></i>
          )}
        </button>
      </div>
      {filteredTranscripts.map((transcript, index) => (
        <div
          key={index}
          className="transcript-item"
          onClick={() => {
            onSelectTranscript(transcript);
            toggleSidebar();
          }}>
          <div className="item-content">
            <div className="item-header">
              <span className="date-time">
                {formatDate(transcript.creationTime.S)}
              </span>
              <div className="checkbox-container">
                <input
                  id={`custom-checkbox-${index}`}
                  type="checkbox"
                  className="custom-checkbox"
                  checked={selectedTranscripts.has(transcript.taskID.S)}
                  onChange={() =>
                    toggleTranscriptSelection(transcript.taskID.S)
                  }
                  onClick={(e) => e.stopPropagation()}
                />
                <label
                  htmlFor={`custom-checkbox-${index}`}
                  onClick={(e) => e.stopPropagation()}></label>
              </div>
            </div>
            <p className="item-text">{transcript.postprocessed_transcript.S}</p>
          </div>

          <div className="item-controles">
            <button
              onClick={(e) => handleShowDownloadModal(e, transcript)}
              className="download-icon amplify-button amplify-button--primary">
              <i className="fa-solid fa-download"></i>
              <span className="hover-text">Преузми транскрипт</span>
            </button>
            <button
              className="audio-play-button amplify-button amplify-button--primary"
              onClick={(e) => handlePlayAudio(e, transcript.taskID.S)}>
              <i className="fa-solid fa-volume-high"></i>
              <span className="hover-text">Слушај транскрипт</span>
            </button>
            <button
              className="add-to-collection amplify-button amplify-button--primary"
              onClick={(e) =>
                handleAddToCollectionClick(e, transcript.taskID.S)
              }>
              <i className="fa-solid fa-plus"></i>
              <span className="hover-text">Додај у колекцију</span>
            </button>
            <button
              className="amplify-button amplify-button--primary delete-transcript"
              onClick={(e) => deleteTranscript(transcript.taskID.S, e)}>
              <i className="fa-solid fa-trash"></i>
              <span className="hover-text">Обриши транскрипт</span>
            </button>
          </div>
        </div>
      ))}

      {isAudioModalOpen && (
        <Modal
          isOpen={isAudioModalOpen}
          onClose={() => setIsAudioModalOpen(false)}>
          <h3>Аудио снимак</h3>
          <AudioPlayer src={currentAudioUrl} />
          <button
            className="amplify-button amplify-button--primary"
            onClick={() => setIsAudioModalOpen(false)}>
            Излаз
          </button>
        </Modal>
      )}

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <h3>Додај у колекцију</h3>
          <select
            className="select-input"
            onChange={handleCollectionSelect}
            value={selectedCollectionId}>
            <option value="">Select a collection</option>
            {collections.map((collection) => (
              <option
                key={collection.collectionID.S}
                value={collection.collectionID.S}>
                {collection.collectionName.S}
              </option>
            ))}
          </select>
          <div>
            <button
              className="amplify-button amplify-button--primary"
              onClick={addToCollection}>
              Додај
            </button>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => setIsModalOpen(false)}>
              Одустани
            </button>
          </div>
        </Modal>
      )}

      {showDownloadModal && (
        <Modal
          isOpen={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}>
          <h3>Преузиманје Транскрипта</h3>

          <select
            className="select-input"
            onChange={(e) => setSelectedFormat(e.target.value)}
            value={selectedFormat}>
            <option value="">Изаберите формат</option>
            <option value="txt">TXT</option>
            <option value="docx">DOCX</option>
          </select>
          <div>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => {
                if (selectedFormat) {
                  downloadTranscript(currentTranscript, selectedFormat);
                  setShowDownloadModal(false);
                } else {
                  alert("За преузимање неопходно је изабрати формат.");
                }
              }}>
              Преузми
            </button>
            <button
              className="amplify-button amplify-button--secondary"
              onClick={() => setShowDownloadModal(false)}>
              Одустани
            </button>
          </div>
        </Modal>
      )}

      {bulkDownloadModal && (
        <Modal
          isOpen={bulkDownloadModal}
          onClose={() => setBulkDownloadModal(false)}>
          <h3>Преузимање транскрипата</h3>
          <select
            className="select-input"
            onChange={(e) => setSelectedFormat(e.target.value)}
            value={selectedFormat}>
            <option value="">Изаберите формат</option>
            <option value="txt">TXT</option>
            <option value="docx">DOCX</option>
          </select>
          <div>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => downloadSelectedTranscripts(true)}>
              Преузми као један фајл
            </button>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => downloadSelectedTranscripts(false)}>
              Преузми као посебне фајлове
            </button>
            <button
              className="amplify-button amplify-button--secondary"
              onClick={() => setBulkDownloadModal(false)}>
              Одустани
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default TranscriptHistory;
