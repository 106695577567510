import React, { useState, useEffect, useCallback } from "react";
import AudioPlayer from "./AudioPlayer";
import Modal from "./Modal";
import FeedbackModal from "./FeedbackModal";
import axios from "axios";
import { downloadTranscriptWithID } from "../services/transcriptService";

const TranscriptDisplay = ({
  taskID,
  onTranscriptLoaded,
  selectedTranscript,
}) => {
  const [transcript, setTranscript] = useState("");
  const [status, setStatus] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [hasPostProcessed, setHasPostProcessed] = useState(false); // Dodato za praćenje statusa postprocesiranja
  const [hasFeedbackSubmitted, setHasFeedbackSubmitted] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [lastSuccessfulTaskID, setLastSuccessfulTaskID] = useState(null);
  const [gptTranscript, setGptTranscript] = useState(""); // Dodato za čuvanje GPT postprocesiranog transkripta
  const [useGptTranscript, setUseGptTranscript] = useState(false);
  const [isAudioModalOpen, setIsAudioModalOpen] = useState(false);
  const [currentAudioUrl, setCurrentAudioUrl] = useState("");
  const [selectedFormat, setSelectedFormat] = useState("");
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const handleShowDownloadModal = (event) => {
    event.stopPropagation();
    setShowDownloadModal(true);
  };

  useEffect(() => {
    if (selectedTranscript) {
      setTranscript(
        selectedTranscript.postprocessed_transcript
          ? selectedTranscript.postprocessed_transcript.S
          : ""
      );
      setLastSuccessfulTaskID(selectedTranscript.taskID.S);
      setUseGptTranscript(false);
      setIsEditable(true);
      setStatus("");
    }
  }, [selectedTranscript]);

  const sendFeedback = async (isGood) => {
    if (!lastSuccessfulTaskID) return;
    try {
      await fetch(
        `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/updateStatus/${lastSuccessfulTaskID}?good=${isGood}`,
        {
          method: "PUT",
        }
      );
      setHasFeedbackSubmitted(true); // Sakrij dugmad nakon uspešnog slanja
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const handlePlayAudio = async (event, taskID) => {
    event.stopPropagation();
    try {
      const response = await axios.get(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/getAudioSignedUrl?taskID=${taskID}`
      );
      setCurrentAudioUrl(response.data.audioFileUrl);
      setIsAudioModalOpen(true);
    } catch (error) {
      console.error("Error fetching audio URL:", error);
    }
  };

  const postProcessTranscript = useCallback(
    async (taskId, useGpt = false) => {
      try {
        if (useGpt) {
          setStatus("postprocessing");
        }
        const postProcessResponse = await fetch(
          `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/postProcess`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ taskID: taskId, gpt: useGpt }), // Dodajemo GPT flag u body
          }
        );

        if (!postProcessResponse.ok) {
          throw new Error("Failed to postprocess the transcript");
        }

        const result = await postProcessResponse.json();
        if (useGpt) {
          setGptTranscript((prevGptTranscript) =>
            prevGptTranscript
              ? `${prevGptTranscript}\n${result.postprocessed_transcript}`
              : result.postprocessed_transcript
          );
          setUseGptTranscript(true);
          setHasPostProcessed(false);
          setStatus("completed");
        } else {
          setTranscript((prevTranscript) =>
            prevTranscript
              ? `${prevTranscript}\n${result.postprocessed_transcript}`
              : result.postprocessed_transcript
          );
          setHasPostProcessed(true);
          setStatus("completed");
          setIsEditable(true);
          setUseGptTranscript(false);
          setHasFeedbackSubmitted(false);
          setLastSuccessfulTaskID(taskId);
        }

        onTranscriptLoaded(null);
      } catch (error) {
        console.error("Error postprocessing transcript:", error);
        setStatus("error");
      }
    },
    [onTranscriptLoaded]
  );

  const toggleTranscriptView = () => {
    setUseGptTranscript(!useGptTranscript);
  };

  useEffect(() => {
    if (!taskID || selectedTranscript) {
      setStatus("");

      return;
    }
    setStatus("pending");
    const interval = setInterval(async () => {
      try {
        const response = await fetch(
          `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/pollStatus/${taskID}`
        );
        const data = await response.json();
        setHasPostProcessed(false); // Resetujemo kada se promeni taskID
        setUseGptTranscript(false); // Resetujemo kada se promeni taskID
        if (data.Count > 0) {
          const currentStatus = data.Items[0].taskStatus.S;
          if (currentStatus === "completed" && !hasPostProcessed) {
            setHasPostProcessed(true); // Postavljamo na true odmah pre postprocesiranja
            postProcessTranscript(taskID).catch((error) => {
              console.error("Error during postprocessing:", error);
              setHasPostProcessed(false); // Resetujemo ako dođe do greške u postprocesiranju
            });
          } else if (currentStatus.includes("error")) {
            setStatus("error");
            onTranscriptLoaded(null);
          } else {
            setStatus("pending");
          }
        }
      } catch (error) {
        console.error("Error fetching transcript status:", error);
        setStatus("error");
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [
    taskID,
    postProcessTranscript,
    onTranscriptLoaded,
    hasPostProcessed,
    selectedTranscript,
  ]);

  const copyTranscript = () => {
    const textToCopy = useGptTranscript ? gptTranscript : transcript;
    navigator.clipboard.writeText(textToCopy);
    alert("Transkript je kopiran!");
  };

  const handleSubmitFeedback = async (wordPairs) => {
    const feedbackData = {
      feedbackType: "Inc/Exp",
      taskID: lastSuccessfulTaskID,
      feedback: wordPairs.map((pair) => ({
        incorrect_word: pair.incorrect,
        expected_word: pair.expected,
      })),
    };

    try {
      const response = await axios.post(
        "https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/sendFeedback",
        feedbackData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Feedback sent successfully:", response.data);
      alert(
        "Хвала вам што сте поделили ваше повратне информације! Ваш допринос помаже нам да унапредимо Хоспи апликацију."
      );
    } catch (error) {
      console.error("Error sending feedback:", error);
      alert(
        "Није нам успело да пошаљемо ваше повратне информације. Молимо вас, покушајте поново."
      );
    }
  };

  const deleteTranscript = () => {
    // Prikazujemo dijalog sa porukom potvrde
    const isConfirmed = window.confirm(
      "Да ли сте сигурни да желите да обришете тренутни транскрипт?"
    );

    // Ako korisnik potvrdi, brišemo transkript
    if (isConfirmed) {
      setTranscript("");
      setGptTranscript("");
      setIsEditable(false);
      setHasPostProcessed(false);
      setStatus("");
      onTranscriptLoaded(null);
      setUseGptTranscript(false);
    }
  };

  return (
    <div className="transcript-display-container ">
      <div className="row">
        {hasPostProcessed && transcript && (
          <button
            className="amplify-button amplify-button--primary"
            onClick={() => postProcessTranscript(lastSuccessfulTaskID, true)} // Dodato dugme za GPT postprocesiranje
            disabled={useGptTranscript} // Onemogućeno ako je već učitan GPT transkript
          >
            Уреди текст
          </button>
        )}
        {gptTranscript && (
          <button
            className="amplify-button amplify-button--primary"
            onClick={toggleTranscriptView} // Prebacivanje između transkripata
          >
            {useGptTranscript ? "Оригинални текст" : "Уређен текст"}
          </button>
        )}
      </div>
      <textarea
        className="TranscriptBox"
        value={useGptTranscript ? gptTranscript : transcript}
        onChange={(e) =>
          useGptTranscript
            ? setGptTranscript(e.target.value)
            : setTranscript(e.target.value)
        }
        readOnly={!isEditable}
      />
      <div className="row">
        <button
          className="amplify-button amplify-button--primary"
          onClick={copyTranscript}
          disabled={!transcript}
          style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <i className="fa-regular fa-copy"></i> Копирај
          <span className="hover-text">Копирај</span>
        </button>
      </div>
      <div className="row">
        <button
          className="amplify-button amplify-button--primary"
          onClick={deleteTranscript}
          disabled={!transcript}>
          <i className="fa-regular fa-trash-can"></i>
          <span className="hover-text">Обриши</span>
        </button>
        <button
          className="amplify-button amplify-button--primary"
          onClick={() => setIsFeedbackModalOpen(true)} // Otvaranje feedback modala
          disabled={!transcript}>
          <i className="fa-regular fa-comments"></i>
          <span className="hover-text">Погрешне Речи</span>
        </button>
        {!hasFeedbackSubmitted && transcript && (
          <div className="row">
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => sendFeedback(true)}>
              <i className="fa-solid fa-thumbs-up"></i>
              <span className="hover-text">Свиђа ми се</span>
            </button>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => sendFeedback(false)}>
              <i className="fa-solid fa-thumbs-down"></i>
              <span className="hover-text">Не свиђа ми се</span>
            </button>
          </div>
        )}
        {(useGptTranscript ? gptTranscript : transcript) && (
          <>
            <button
              className="amplify-button amplify-button--primary"
              onClick={(e) => handlePlayAudio(e, lastSuccessfulTaskID)}>
              <i className="fa-solid fa-volume-high"></i>
              <span className="hover-text">Слушај транскрипт</span>
            </button>
            <button
              onClick={(e) => handleShowDownloadModal(e)}
              className="amplify-button amplify-button--primary">
              <i className="fa-solid fa-download"></i>
              <span className="hover-text">Преузми транскрипт</span>
            </button>
          </>
        )}
      </div>
      {isAudioModalOpen && (
        <Modal
          isOpen={isAudioModalOpen}
          onClose={() => setIsAudioModalOpen(false)}>
          <h3>Аудио снимак</h3>
          <AudioPlayer src={currentAudioUrl} />
          <button
            className="amplify-button amplify-button--primary"
            onClick={() => setIsAudioModalOpen(false)}>
            Излаз
          </button>
        </Modal>
      )}

      {isFeedbackModalOpen && (
        <FeedbackModal
          isOpen={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
          onSubmitFeedback={handleSubmitFeedback}
        />
      )}

      {showDownloadModal && (
        <Modal
          isOpen={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}>
          <h3>Преузиманје Транскрипта</h3>

          <select
            className="select-input"
            onChange={(e) => setSelectedFormat(e.target.value)}
            value={selectedFormat}>
            <option value="">Изаберите формат</option>
            <option value="txt">TXT</option>
            <option value="docx">DOCX</option>
          </select>
          <div>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => {
                if (selectedFormat) {
                  downloadTranscriptWithID(
                    lastSuccessfulTaskID,
                    selectedFormat
                  );
                  setShowDownloadModal(false);
                } else {
                  alert("За преузимање неопходно је изабрати формат.");
                }
              }}>
              Преузми
            </button>
            <button
              className="amplify-button amplify-button--secondary"
              onClick={() => setShowDownloadModal(false)}>
              Одустани
            </button>
          </div>
        </Modal>
      )}
      {status === "error" && (
        <div className="status-popup">
          Дошло је до грешке приликом обраде транскрипта.
        </div>
      )}
      {status === "pending" && (
        <div className="status-popup">Обрада транскрипта у току...</div>
      )}
      {status === "postprocessing" && (
        <div className="status-popup">Уређивање текста у току...</div>
      )}
    </div>
  );
};

export default TranscriptDisplay;
