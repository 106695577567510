import React, { useState } from "react";
import Modal from "./Modal";
import axios from "axios";
import {
  downloadTranscript,
  bulkDownloadSelectedTranscripts,
} from "../services/transcriptService";
import AudioPlayer from "./AudioPlayer";

const TranscriptCollections = ({
  transcripts,
  collections,
  onSelectTranscript,
  updateTranscripts,
  toggleSidebar,
  refreshData,
  username,
}) => {
  const [openCollectionIds, setOpenCollectionIds] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [editCollectionId, setEditCollectionId] = useState(null);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [editCollectionName, setEditCollectionName] = useState("");
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [currentTranscript, setCurrentTranscript] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [isAudioModalOpen, setIsAudioModalOpen] = useState(false);
  const [currentAudioUrl, setCurrentAudioUrl] = useState("");
  const [bulkDownloadModalForCollection, setBulkDownloadModalForCollection] =
    useState(null);

  const openBulkDownloadModal = (collection) => {
    setBulkDownloadModalForCollection(collection);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Nepoznato";
    const date = new Date(dateString);
    return date.toLocaleString("sr-RS", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const handleShowDownloadModal = (event, transcript) => {
    event.stopPropagation();
    setCurrentTranscript(transcript);
    setShowDownloadModal(true);
  };

  const toggleCollection = (collectionID) => {
    const newOpenCollectionIds = new Set(openCollectionIds);
    if (newOpenCollectionIds.has(collectionID)) {
      newOpenCollectionIds.delete(collectionID);
    } else {
      newOpenCollectionIds.add(collectionID);
    }
    setOpenCollectionIds(newOpenCollectionIds);
  };

  const handleNewCollectionNameChange = (event) => {
    setNewCollectionName(event.target.value);
  };

  const createCollection = async (collectionName) => {
    if (!collectionName.trim()) {
      alert("Име колекције не сме бити празно.");
      return;
    }

    try {
      const response = await axios.post(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/cudCollection`,
        { action: "add", collection: collectionName, doctor: username }
      );

      console.log("Collection created successfully:", response.data);
      refreshData();
    } catch (error) {
      console.error("Error creating collection:", error);
    }
  };

  const handleCreateCollection = () => {
    createCollection(newCollectionName);
    setIsModalOpen(false);
    setNewCollectionName("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNewCollectionName("");
  };

  const deleteTranscript = async (event, taskID) => {
    event.stopPropagation(); // Sprečava pokretanje onClick događaja za selektovanje transkripta

    const userConfirmation = window.confirm(
      "Да ли сте сигурни да желите да обришете транскрипт?"
    );
    if (!userConfirmation) {
      return;
    }

    try {
      await axios.put(
        `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/markAsDeleted/${taskID}`
      );
      const updatedTranscripts = transcripts.filter(
        (t) => t.taskID.S !== taskID
      );
      updateTranscripts(updatedTranscripts);
    } catch (error) {
      console.error("Error deleting transcript:", error);
    }
  };

  const isCollectionOpen = (collectionID) =>
    openCollectionIds.has(collectionID);

  const getTranscriptsForCollection = (collection) => {
    // Proverite da li postoji taskIDs.L i da li je niz
    if (collection.taskIDs && Array.isArray(collection.taskIDs.L)) {
      // Preuzmite niz ID-ova kao običan niz stringova
      const taskIDs = collection.taskIDs.L.map((item) => item.S);
      // Filtrirajte transkripte koji se nalaze u taskIDs nizu
      return transcripts.filter((transcript) =>
        taskIDs.includes(transcript.taskID.S)
      );
    }
    return []; // Vrati prazan niz ako ne postoji taskIDs ili nije u očekivanom formatu
  };

  const handleRemoveFromCollectionClick = async (
    event,
    taskID,
    collectionID
  ) => {
    event.stopPropagation();

    const userConfirmation = window.confirm(
      "Да ли сте сигурни да желите да уклоните овај транскрипт из колекције?"
    );
    if (!userConfirmation) {
      return;
    }

    try {
      await axios.post(
        "https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/manageTaskInCollection",
        {
          taskID: taskID,
          collectionID: collectionID,
          action: "remove",
        }
      );
      refreshData(); // Osvežava podatke nakon uklanjanja transkripta iz kolekcije
      alert("Успешно сте уклонили транскрипт из колекције!");
    } catch (error) {
      console.error("Error removing transcript from collection:", error);
      alert("Дошло је до грешке приликом уклањања транскрипта из колекције.");
    }
  };

  const handleEditCollection = (event, collectionId, currentName) => {
    event.stopPropagation();
    setEditCollectionId(collectionId);
    setEditCollectionName(currentName);
    setIsEditModalOpen(true);
  };

  const handleUpdateCollection = async () => {
    if (!editCollectionName.trim()) {
      alert("Име колекције не сме бити празно.");
      return;
    }
    try {
      await axios.post(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/cudCollection`,
        {
          action: "edit",
          collectionID: editCollectionId,
          newName: editCollectionName,
        }
      );
      refreshData();
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error updating collection:", error);
    }
  };

  const handlePlayAudio = async (event, taskID) => {
    event.stopPropagation();
    try {
      const response = await axios.get(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/getAudioSignedUrl?taskID=${taskID}`
      );
      setCurrentAudioUrl(response.data.audioFileUrl);
      setIsAudioModalOpen(true);
    } catch (error) {
      console.error("Error fetching audio URL:", error);
    }
  };

  const handleDeleteCollection = async (event, collectionId) => {
    event.stopPropagation();

    const userConfirmation = window.confirm(
      "Да ли сте сигурни да желите да обришете ову колекцију?"
    );
    if (!userConfirmation) {
      return;
    }

    try {
      await axios.post(
        `https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/cudCollection`,
        { action: "remove", collectionID: collectionId }
      );
      refreshData();
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  const bulkDownloadTranscriptsFromCollection = (collection, mergeFiles) => {
    const transcriptsToDownload = getTranscriptsForCollection(collection);
    if (transcriptsToDownload.length > 0) {
      bulkDownloadSelectedTranscripts(
        transcriptsToDownload,
        new Set(transcriptsToDownload.map((t) => t.taskID.S)),
        selectedFormat,
        mergeFiles
      );
    } else {
      alert("Нема транскрипата за преузимање у овој колекцији.");
    }
  };

  const handleSelectAllTranscriptsFromCollection = (collection) => {
    const transcriptsFromCollection = getTranscriptsForCollection(collection);
    onSelectTranscript(transcriptsFromCollection);
  };

  return (
    <div className="transcript-collections">
      <h2>Колекција извештаја</h2>
      <button
        className="amplify-button amplify-button--primary"
        onClick={() => setIsModalOpen(true)}>
        Креирај нову колекцију
      </button>

      {isAudioModalOpen && (
        <Modal
          isOpen={isAudioModalOpen}
          onClose={() => setIsAudioModalOpen(false)}>
          <h3>Аудио снимак</h3>
          <AudioPlayer src={currentAudioUrl} />
          <button
            className="amplify-button amplify-button--primary"
            onClick={() => setIsAudioModalOpen(false)}>
            Излаз
          </button>
        </Modal>
      )}

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div>
            <input
              className="search-input"
              type="text"
              value={newCollectionName}
              onChange={handleNewCollectionNameChange}
              placeholder="Име колекције"
            />
            <div>
              <button
                className="amplify-button amplify-button--primary"
                onClick={handleCreateCollection}>
                Креирај
              </button>
              <button
                className="amplify-button amplify-button--primary"
                onClick={handleCancel}>
                Одустани
              </button>
            </div>
          </div>
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}>
          <div>
            <input
              className="search-input"
              type="text"
              value={editCollectionName}
              onChange={(e) => setEditCollectionName(e.target.value)}
              placeholder="Ново име колекције"
            />
            <button
              className="amplify-button amplify-button--primary"
              onClick={handleUpdateCollection}>
              Сачувај
            </button>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => setIsEditModalOpen(false)}>
              Откажи
            </button>
          </div>
        </Modal>
      )}

      {showDownloadModal && (
        <Modal
          isOpen={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}>
          <h3>Преузиманје Транскрипта</h3>

          <select
            className="select-input"
            onChange={(e) => setSelectedFormat(e.target.value)}
            value={selectedFormat}>
            <option value="">Изаберите формат</option>
            <option value="txt">TXT</option>
            <option value="docx">DOCX</option>
          </select>
          <div>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => {
                if (selectedFormat) {
                  downloadTranscript(currentTranscript, selectedFormat);
                  setShowDownloadModal(false);
                } else {
                  alert("За преузимање неопходно је изабрати формат.");
                }
              }}>
              Преузми
            </button>
            <button
              className="amplify-button amplify-button--secondary"
              onClick={() => setShowDownloadModal(false)}>
              Одустани
            </button>
          </div>
        </Modal>
      )}
      {bulkDownloadModalForCollection && (
        <Modal
          isOpen={!!bulkDownloadModalForCollection}
          onClose={() => setBulkDownloadModalForCollection(null)}>
          <h3>Преузимање транскрипата из колекције</h3>
          <select
            className="select-input"
            onChange={(e) => setSelectedFormat(e.target.value)}
            value={selectedFormat}>
            <option value="">Изаберите формат</option>
            <option value="txt">TXT</option>
            <option value="docx">DOCX</option>
          </select>
          <div>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => {
                bulkDownloadTranscriptsFromCollection(
                  bulkDownloadModalForCollection,
                  true
                );
                setBulkDownloadModalForCollection(null);
              }}>
              Преузми као један фајл
            </button>
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => {
                bulkDownloadTranscriptsFromCollection(
                  bulkDownloadModalForCollection,
                  false
                );
                setBulkDownloadModalForCollection(null);
              }}>
              Преузми као посебне фајлове
            </button>
            <button
              className="amplify-button amplify-button--secondary"
              onClick={() => setBulkDownloadModalForCollection(null)}>
              Одустани
            </button>
          </div>
        </Modal>
      )}

      {collections.map((collection, index) => (
        <div key={index} className="collection">
          <div
            className="collection-title"
            onClick={() => toggleCollection(collection.collectionID.S)}>
            {collection.collectionName.S}

            <div className="collection-controles ">
              <button
                className="collection-select-all amplify-button amplify-button--primary"
                onClick={() =>
                  handleSelectAllTranscriptsFromCollection(collection)
                }>
                <i className="fa-solid fa-arrow-right-to-bracket"></i>
                <span className="hover-text">Одабери Колекцију</span>
              </button>
              <button
                className="collection-download-icon amplify-button amplify-button--primary"
                onClick={(e) => {
                  e.stopPropagation();
                  openBulkDownloadModal(collection);
                }}>
                <i className="fa-solid fa-download"></i>
                <span className="hover-text">Преузми колекцију</span>
              </button>
              <button
                className="collection-edit amplify-button amplify-button--primary"
                onClick={(e) =>
                  handleEditCollection(
                    e,
                    collection.collectionID.S,
                    collection.collectionName.S
                  )
                }>
                <i className="fa-solid fa-pen-to-square"></i>
                <span className="hover-text">Измени колекцију</span>
              </button>
              <button
                className="collection-delete-transcript amplify-button amplify-button--primary"
                onClick={(e) =>
                  handleDeleteCollection(e, collection.collectionID.S)
                }>
                <i className="fa-solid fa-trash"></i>
                <span className="hover-text">Обриши колекцију</span>
              </button>
            </div>
          </div>
          {isCollectionOpen(collection.collectionID.S) && (
            <div className="collection-transcripts">
              {getTranscriptsForCollection(collection).map(
                (transcript, idx) => (
                  <div
                    key={idx}
                    className="transcript-item"
                    onClick={() => {
                      onSelectTranscript(transcript);
                      toggleSidebar();
                    }}>
                    <div className="item-content">
                      <div className="item-header">
                        <span className="date-time">
                          {formatDate(transcript.creationTime.S)}
                        </span>
                      </div>
                      <p className="item-text">
                        {transcript.postprocessed_transcript.S}
                      </p>
                    </div>
                    <div className="item-controles ">
                      <button
                        onClick={(e) => handleShowDownloadModal(e, transcript)}
                        className="download-icon amplify-button amplify-button--primary">
                        <i className="fa-solid fa-download"></i>
                        <span className="hover-text">Преузми транскрипт</span>
                      </button>
                      <button
                        className="audio-play-button amplify-button amplify-button--primary"
                        onClick={(e) =>
                          handlePlayAudio(e, transcript.taskID.S)
                        }>
                        <i className="fa-solid fa-volume-high"></i>
                        <span className="hover-text">Слушај транскрипт</span>
                      </button>

                      <button
                        className="add-to-collection amplify-button amplify-button--primary"
                        onClick={(e) =>
                          handleRemoveFromCollectionClick(
                            e,
                            transcript.taskID.S,
                            collection.collectionID.S
                          )
                        }>
                        <i className="fa-regular fa-square-minus"></i>
                        <span className="hover-text">
                          Уклони транскрипт из колекције
                        </span>
                      </button>
                      <button
                        className="delete-transcript amplify-button amplify-button--primary"
                        onClick={(e) =>
                          deleteTranscript(e, transcript.taskID.S, e)
                        }>
                        <i className="fa-solid fa-trash"></i>
                        <span className="hover-text">Обриши транскрипт</span>
                      </button>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TranscriptCollections;
