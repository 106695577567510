import React, { useState, useEffect } from "react";
import axios from "axios";
import TranscriptHistory from "./TranscriptHistory";
import TranscriptCollections from "./TranscriptCollections";
import EpikrizaHistory from "./EpikrizaHistory";

const HistorySidebar = ({
  username,
  refresh,
  onSelectTranscript,
  isSidebarOpen,
  toggleSidebar,
}) => {
  const [transcripts, setTranscripts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadedItems, setLoadedItems] = useState(20);
  const [fetchTranscriptsCount, setfetchTranscriptsCount] = useState(0);
  const [activeTab, setActiveTab] = useState("history");
  const [refreshTranscripts, setRefreshTranscripts] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const fetchTranscripts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/getUserHistory/${username}?limit=${loadedItems}`
        );
        if (isRefreshing) {
          setLoadMore(
            response.data.Count === fetchTranscriptsCount ? false : true
          );
        }
        setfetchTranscriptsCount(response.data.Count);
        const filteredTranscripts = response.data.Items.filter(
          (t) =>
            t.postprocessed_transcript && t.postprocessed_transcript.S.trim()
        );
        setTranscripts(filteredTranscripts); // Reset loadedItems when new data is fetched

        const responseCollection = await axios.get(
          `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/getCollectionsByDoctor/${username}`
        );

        setCollections(responseCollection.data.Items);
      } catch (error) {
        console.error("Error fetching transcripts:", error);
      } finally {
        setLoading(false);
        setIsRefreshing(false);
      }
    };

    fetchTranscripts();
  }, [username, loadedItems, refresh, refreshTranscripts]);

  // Add refresh to the dependency array

  const updateTranscripts = (updatedTranscripts) => {
    setIsRefreshing(true);
    setTranscripts(updatedTranscripts);
    setRefreshTranscripts((prev) => !prev);
  };

  return (
    <div className="sidebar">
      <button className="menu-toggle" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`sidebar-content ${isSidebarOpen ? "open" : "closed"}`}>
        <div className="tabs">
          <button
            className="amplify-button amplify-button--primary"
            onClick={() => setActiveTab("history")}>
            Историја
          </button>
          <button
            className="amplify-button amplify-button--primary"
            onClick={() => setActiveTab("collections")}>
            Колекције
          </button>
          {/* <button
            className="amplify-button amplify-button--primary"
            onClick={() => setActiveTab("epikriza")}>
            Епикриза
          </button> */}
        </div>

        {activeTab === "history" && (
          <TranscriptHistory
            transcripts={transcripts}
            onSelectTranscript={onSelectTranscript}
            collections={collections}
            toggleSidebar={toggleSidebar}
            updateTranscripts={updateTranscripts}
            refreshData={() => {
              setRefreshTranscripts((prev) => !prev);
            }}
          />
        )}
        {activeTab === "collections" && (
          <TranscriptCollections
            transcripts={transcripts}
            collections={collections}
            onSelectTranscript={onSelectTranscript}
            toggleSidebar={toggleSidebar}
            updateTranscripts={updateTranscripts}
            username={username}
            refreshData={() => {
              setRefreshTranscripts((prev) => !prev);
            }}
          />
        )}
        {activeTab === "epikriza" && <EpikrizaHistory username={username} />}
        {loadMore && (
          <button
            className="amplify-button amplify-button--primary"
            onClick={() => {
              setIsRefreshing(true);
              setLoadedItems((prev) => prev + 20);
            }}
            disabled={loading}>
            {loading ? "Учитавање..." : "Учитај још"}
          </button>
        )}
      </div>
    </div>
  );
};

export default HistorySidebar;
