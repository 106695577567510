import React, { useState, useEffect, useCallback } from "react";

const AudioRecorder = ({ onAudioUpload, isProcessing }) => {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [timer, setTimer] = useState(9 * 60); // 9 минута у секундама
  const [audioLevel, setAudioLevel] = useState(0);
  const [analyser, setAnalyser] = useState(null);
  const [audioStream, setAudioStream] = useState(null);

  useEffect(() => {
    const initializeAudio = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(stream);
      const analyserNode = audioContext.createAnalyser();
      source.connect(analyserNode);
      setAnalyser(analyserNode);
    };

    initializeAudio();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (analyser) {
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      const updateAudioLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const level =
          dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;
        setAudioLevel(level / 256); // Normalizujemo nivo zvuka
        requestAnimationFrame(updateAudioLevel);
      };
      updateAudioLevel();
    }
  }, [analyser]);

  const finishRecording = useCallback(() => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
    }
    setRecording(false);
    setMediaRecorder(null);
    setTimer(9 * 60); // Ресет тајмера
  }, [mediaRecorder]);

  useEffect(() => {
    let interval;
    if (recording && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      finishRecording();
    }
    return () => clearInterval(interval);
  }, [recording, timer, finishRecording]);

  const toggleRecording = async () => {
    if (!recording && audioStream) {
      const recorder = new MediaRecorder(audioStream);
      let audioChunks = [];

      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      recorder.start();
      setRecording(true);
      setMediaRecorder(recorder);

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, {
          type: "audio/wav; codecs=MS_PCM",
        });
        onAudioUpload(audioBlob);
        audioChunks = [];
      };
    } else {
      finishRecording();
    }
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  return (
    <div>
      <button
        onClick={toggleRecording}
        disabled={isProcessing}
        className={`amplify-button amplify-button--primary ${
          recording || isProcessing ? "recording" : ""
        }`}>
        {isProcessing
          ? "Обрада..."
          : recording
          ? `Заустави снимање - ${formatTime(timer)}`
          : "Започните снимање"}
      </button>

      <div className="row" style={{ marginBottom: "1rem" }}>
        <i className="fa-solid fa-microphone"></i>
        <div
          style={{
            height: "1rem", // Visina sound bara
            width: "100%", // Širina da odgovara dugmetu
            backgroundColor: "#ccc",
            borderRadius: "2px",
          }}>
          <div
            style={{
              height: "100%",
              width: `${audioLevel * 3 * 100}%`, // Popunjenost u zavisnosti od audio levela
              backgroundColor: "hsl(190, 95%, 30%)", // Boja popunjenosti
              transition: "width 0.06s ease", // Animacija promene širine
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioRecorder;
