import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./Modal";

const EpikrizaHistory = ({ username }) => {
  const [epikrize, setEpikrize] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedEpikriza, setSelectedEpikriza] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState("");

  useEffect(() => {
    const fetchEpikrize = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/getEpikrizeByUsername/${username}`
        );
        if (response.data) {
          const validEpikrize = response.data.Items.filter(
            (item) => item.epikriza && item.epikriza.S
          );
          setEpikrize(validEpikrize);
        } else {
          // Postavi epikrize na prazan niz ako odgovor nije niz
          setEpikrize([]);
          console.error("Odgovor nije niz:", response.data);
        }
      } catch (error) {
        console.error("Error fetching epikrize:", error);
        setEpikrize([]); // Postavi epikrize na prazan niz u slučaju greške
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      fetchEpikrize();
    }
  }, [username]);

  const formatDate = (dateString) => {
    if (!dateString) return "Nepoznato";
    const date = new Date(dateString);
    return date.toLocaleString("sr-RS", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleShowDownloadModal = (epikriza) => {
    setSelectedEpikriza(epikriza);
    setShowDownloadModal(true);
  };

  const filteredEpikrize = epikrize.filter(
    (epikriza) =>
      epikriza.epikriza.S.toLowerCase().includes(searchTerm.toLowerCase()) ||
      formatDate(epikriza.creationTime.S).includes(searchTerm)
  );

  const downloadEpikriza = () => {
    if (!selectedFormat) {
      alert("За преузимање неопходно је изабрати формат.");
      return;
    }

    let textToDownload = selectedEpikriza.pp_epikriza
      ? selectedEpikriza.pp_epikriza.S
      : selectedEpikriza.epikriza.S;

    const blob = new Blob([textToDownload], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `epikriza.${selectedFormat === "txt" ? "txt" : "docx"}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShowDownloadModal(false);
  };

  return (
    <div className="epikriza-history">
      <h2>Историја Епикриза</h2>
      <input
        className="search-input"
        type="text"
        placeholder="Претражи по тексту или датуму..."
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {isLoading ? (
        <p>Учитавање...</p>
      ) : (
        filteredEpikrize.map((epikriza, index) => (
          <div key={index} className="transcript-item">
            <div className="item-content">
              <div className="item-header">
                <span className="date-time">
                  {formatDate(epikriza.creationTime.S)}
                </span>
              </div>
              <p className="item-text">
                {epikriza.pp_epikriza
                  ? epikriza.pp_epikriza.S
                  : epikriza.epikriza.S}
              </p>
            </div>
            <div className="item-controles">
              <button
                className="download-icon amplify-button amplify-button--primary"
                onClick={() => handleShowDownloadModal(epikriza)}>
                <i className="fa-solid fa-download"></i>
                <span className="hover-text">Преузми епикризу</span>
              </button>
            </div>
          </div>
        ))
      )}

      {showDownloadModal && selectedEpikriza && (
        <Modal
          isOpen={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}>
          <h3>Преузиманје Епикризе</h3>
          <div>
            <p className="paragraph-scroll">
              {selectedEpikriza.pp_epikriza
                ? selectedEpikriza.pp_epikriza.S
                : selectedEpikriza.epikriza.S}
            </p>
            <div className="row">
              <select
                className="select-input"
                onChange={(e) => setSelectedFormat(e.target.value)}
                value={selectedFormat}>
                <option value="">Изаберите формат</option>
                <option value="txt">TXT</option>
                <option value="docx">DOCX</option>
              </select>
            </div>
            <div>
              <button
                className="amplify-button amplify-button--primary"
                onClick={() => {
                  if (selectedFormat) {
                    downloadEpikriza();
                    setShowDownloadModal(false);
                  } else {
                    alert("За преузимање неопходно је изабрати формат.");
                  }
                }}>
                Преузми
              </button>
              <button
                className="amplify-button amplify-button--primary"
                onClick={() => setShowDownloadModal(false)}>
                Одустани
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EpikrizaHistory;
