import React, { useState } from "react";
import Modal from "./Modal";

const FeedbackModal = ({ isOpen, onClose, onSubmitFeedback }) => {
  const [wordPairs, setWordPairs] = useState([{ incorrect: "", expected: "" }]);

  const handleAddPair = () => {
    // Samo dodaj novi par ako je prethodni par validan (incorrect nije prazan)
    if (wordPairs[wordPairs.length - 1].incorrect !== "") {
      setWordPairs([...wordPairs, { incorrect: "", expected: "" }]);
    } else {
      alert(
        "Молимо попуните нетачну реч последњег пара пре него што додате нову."
      );
    }
  };

  const handleRemovePair = (index) => {
    if (index === 0 && wordPairs.length === 1) {
      // Ako je prvi red i jedini red, resetuj polja ali ne uklanjaj red
      setWordPairs([{ incorrect: "", expected: "" }]);
    } else if (
      wordPairs[index].incorrect !== "" ||
      wordPairs[index].expected !== ""
    ) {
      // Ako su polja popunjena, resetuj ih
      setWordPairs(
        wordPairs.map((pair, idx) => {
          if (idx === index) {
            return { incorrect: "", expected: "" };
          }
          return pair;
        })
      );
    } else {
      // Ako su polja prazna, ukloni red
      setWordPairs(wordPairs.filter((_, idx) => idx !== index));
    }
  };

  const handleChange = (index, type, value) => {
    const newPairs = wordPairs.map((pair, idx) => {
      if (idx === index) {
        return { ...pair, [type]: value };
      }
      return pair;
    });
    setWordPairs(newPairs);
  };

  const handleSubmit = () => {
    // Filtriraj parove gde je 'incorrect' prazno i šalji samo one koji imaju unetu vrednost
    const validPairs = wordPairs.filter((pair) => pair.incorrect.trim() !== "");
    if (validPairs.length > 0) {
      onSubmitFeedback(validPairs);
      onClose();
    } else {
      alert("Унесите најмање један важећи пар пре слања.");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2>Повратна информација за Транскрипт</h2>
      {wordPairs.map((pair, index) => (
        <div className="row">
          <div key={index} className="row">
            <input
              className="feedback-input"
              type="text"
              placeholder="Нетачна реч"
              value={pair.incorrect}
              onChange={(e) => handleChange(index, "incorrect", e.target.value)}
            />
            <input
              className="feedback-input"
              type="text"
              placeholder="Очекивана реч"
              value={pair.expected}
              onChange={(e) => handleChange(index, "expected", e.target.value)}
            />
            <button
              className="amplify-button amplify-button--primary"
              onClick={() => handleRemovePair(index)}
              disabled={index === 0 && wordPairs.length === 1}>
              -
            </button>
          </div>
        </div>
      ))}
      <div className="row">
        {" "}
        <button
          className="amplify-button amplify-button--primary"
          onClick={handleAddPair}>
          Додај пар
        </button>
        <button
          className="amplify-button amplify-button--primary"
          onClick={handleSubmit}>
          Пошаљи
        </button>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
