/* eslint no-control-regex: "off" */

import React, { useState, useEffect } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import TranscriptDisplay from "./components/TranscriptEditor";
import AudioRecorder from "./components/AudioRecorder";
import Epikriza from "./components/Epikriza";
import HistorySidebar from "./components/HistorySidebar";
import Modal from "./components/Modal";
import {
  generateTask,
  uploadAudioToServer,
  addToCollection,
  synchronizeAudios,
  saveAudioLocally,
} from "./services/audioService";
import axios from "axios";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";

function App() {
  const [taskID, setTaskID] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [refreshHistory, setRefreshHistory] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [collections, setCollections] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [activeTab, setActiveTab] = useState("diktafon");
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 500);
  const [selectedTranscriptText, setSelectedTranscriptText] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    function handleResize() {
      setIsNarrowScreen(window.innerWidth < 500);
    }

    window.addEventListener("resize", handleResize);

    // Očisti event listener kada komponenta nije više u DOM-u
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderTabContent = (user) => {
    switch (activeTab) {
      case "diktafon":
        return (
          <main>
            <AudioRecorder
              onAudioUpload={(audioBlob) =>
                handleAudioUpload(audioBlob, user.username)
              }
              isProcessing={isProcessing}
            />
            <select
              className="select-input"
              value={selectedCollectionId}
              onChange={(e) => setSelectedCollectionId(e.target.value)}>
              <option value="">Изаберите колекцију</option>
              {collections.map((collection) => (
                <option
                  key={collection.collectionID.S}
                  value={collection.collectionID.S}>
                  {collection.collectionName.S}
                </option>
              ))}
            </select>
            <TranscriptDisplay
              taskID={taskID}
              onTranscriptLoaded={resetTaskID}
              selectedTranscript={selectedTranscript}
            />
          </main>
        ); // Ovde ide sadržaj za diktafon
      case "epikriza":
        return (
          <main>
            <Epikriza
              username={username}
              selectedTranscriptText={selectedTranscriptText}
            />
          </main>
        ); // Ovde ide sadržaj za epikriza
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleOnline = async () => {
      console.log("Aplikacija je ponovo online.");
      if (!isSynchronizing) {
        const { username, userId } = await getCurrentUser();
        setIsSynchronizing(true);
        await synchronizeAudios(username, setIsSynchronizing);
      }
    };

    const handleOffline = () => {
      console.log("Aplikacija je sada offline.");
      // Ovde možete dodati logiku koja se izvršava kada aplikacija pređe u offline režim
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  Hub.listen("auth", ({ payload }) => {
    switch (payload.event) {
      case "signedIn":
        setUserLoggedIn(true);
        setUsername(payload.data.username);
        break;
      case "signedOut":
        setUserLoggedIn(false);
        setUsername("");
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await axios.get(
          `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/getCollectionsByDoctor/${username}`
        );
        setCollections(response.data.Items);
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    if (username) {
      fetchCollections();
    }
  }, [username]);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      try {
        const { username, userId } = await getCurrentUser();
        setUserLoggedIn(true);
        setUsername(username);
        console.log(`The username: ${username}`);
        console.log(`The userId: ${userId}`);
      } catch (err) {
        setUserLoggedIn(false);
      }
    };

    checkUserLoggedIn();
  }, []);

  const selectTranscript = (transcripts) => {
    if (Array.isArray(transcripts) && transcripts.length === 0) {
      return;
    }

    if (Array.isArray(transcripts) && transcripts.length > 0) {
      const transcriptTexts = transcripts
        .map((t) => t.postprocessed_transcript.S)
        .join("\n");
      setSelectedTranscriptText(transcriptTexts);

      // Pronalazi transkript s najnovijim datumom
      const latestTranscript = transcripts.reduce((latest, current) => {
        return new Date(latest.creationTime.S) >
          new Date(current.creationTime.S)
          ? latest
          : current;
      });

      setSelectedTranscript(latestTranscript);
    } else {
      setSelectedTranscriptText(transcripts.postprocessed_transcript.S);
      setSelectedTranscript(transcripts);
    }
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = (signOut) => {
    const isConfirmed = window.confirm("Да ли желите да се одјавите?");
    if (isConfirmed) {
      signOut(); // Call the signOut function provided by Authenticator
    }
  };

  const handleAudioUpload = async (audioBlob, username) => {
    setIsProcessing(true);

    try {
      if (navigator.onLine) {
        const id = await generateTask(username);
        console.log("taskID: " + id);
        setTaskID(id);
        setSelectedTranscript(null);
        await uploadAudioToServer(audioBlob, id);

        if (selectedCollectionId) {
          await addToCollection(id, selectedCollectionId);
        }
        if (!isSynchronizing) {
          await synchronizeAudios(username, setIsSynchronizing);
        }
      } else {
        throw new Error("No internet connection");
      }
    } catch (error) {
      console.error("Error during audio upload or task generation:", error);
      await saveAudioLocally(audioBlob);
      setIsProcessing(false);
      alert("Failed to upload audio. It has been saved locally.");
    } finally {
    }
  };

  const resetTaskID = () => {
    setTaskID(null);
    setIsProcessing(false);
    setRefreshHistory((prev) => !prev);
  };

  const handleSubmitFeedback = () => {
    if (feedback.trim()) {
      fetch(
        "https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/sendFeedback",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            feedback: feedback,
            feedbackType: "Suggestion",
            username: username,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            alert("Повратне информације су успешно послате");
            setShowFeedbackModal(false);
            setFeedback("");
          } else {
            alert("Слање повратних информација није успело");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Слање повратних информација није успело");
        });
    } else {
      alert("Унесите повратне информације");
    }
  };

  const handleRegisterClick = () => {
    setShowPopup(true);
  };

  const handleCancelClick = () => {
    setShowPopup(false);
    setEmail("");
  };

  const handleSubmit = async () => {
    // Regularni izraz za proveru validnosti e-mail adrese
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    // Validacija emaila
    if (!email || !email.trim() || !emailRegex.test(email)) {
      alert("Молимо Вас унесите валидну емаил адресу.");
      return;
    }

    try {
      const response = await axios.post(
        "https://079g7fp7o4.execute-api.eu-central-1.amazonaws.com/Prod/register_email",
        { email }
      );
      console.log(response.data);
      setShowPopup(false);
      setEmail(""); // Resetovanje e-mail polja nakon uspešnog slanja
      alert("Успешно сте послали захтев за регистрацију!");
    } catch (error) {
      console.error("Došlo je do greške pri slanju zahteva", error);
    }
  };

  const setActiveTabAndResetTranscript = (tab) => {
    setActiveTab(tab);
    setSelectedTranscriptText(""); // Resetovanje teksta transkripta
    setSelectedTranscript(null); // Resetovanje objekta transkripta
  };

  return (
    <div className="App">
      <div className="container">
        <h1>Hospy</h1>
        {!userLoggedIn && (
          <h3 className="subtitle">Hospital Reporting Assistant </h3>
        )}
        {userLoggedIn && (
          <HistorySidebar
            username={username}
            refresh={refreshHistory}
            onSelectTranscript={selectTranscript}
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        )}
        <Authenticator hideSignUp={true}>
          {({ signOut, user }) => {
            return (
              <main>
                {/* <div className="tab-navigation">
                  <button
                    className="amplify-button amplify-button--primary"
                    onClick={() => setActiveTabAndResetTranscript("diktafon")}>
                    {isNarrowScreen ? (
                      <i className="fa-solid fa-microphone-lines"></i>
                    ) : (
                      "Транскрипција"
                    )}
                  </button>
                  <button
                    className="amplify-button amplify-button--primary"
                    onClick={() => setActiveTabAndResetTranscript("epikriza")}>
                    {isNarrowScreen ? (
                      <i className="fa-solid fa-file-lines"></i>
                    ) : (
                      "Епикриза"
                    )}
                  </button>
                </div> */}
                <div className="tab-content">{renderTabContent(user)}</div>
                <button
                  className="amplify-button amplify-button--primary"
                  onClick={() => setShowFeedbackModal(true)}>
                  Ваше Сугестије
                  <span className="hover-text">Додај Сугестију</span>
                </button>
                <button
                  className={"amplify-button amplify-button--primary"}
                  onClick={() => handleLogout(signOut)}>
                  Одјава
                </button>
              </main>
            );
          }}
        </Authenticator>
        <div>
          {!userLoggedIn && (
            <button
              className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
              onClick={handleRegisterClick}>
              Захтев за регистрацију!
            </button>
          )}
          {showPopup && (
            <div className="modal-overlay">
              <div className="modal-content">
                <label htmlFor="emailInput" className="emailInputLabel">
                  Имејл адреса:
                </label>
                <input
                  id="emailInput"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Молимо Вас унесите имејл адресу."
                  className="emailInput"
                />
                <div className="row">
                  <button
                    className={"amplify-button amplify-button--primary"}
                    onClick={handleSubmit}>
                    Пошаљи
                  </button>
                  <button
                    className={"amplify-button amplify-button--primary"}
                    onClick={handleCancelClick}>
                    Одустани
                  </button>
                </div>
              </div>
            </div>
          )}

          {showFeedbackModal && (
            <Modal
              isOpen={showFeedbackModal}
              onClose={() => setShowFeedbackModal(false)}>
              <h3>Сугестије</h3>
              <textarea
                className="textarea-input"
                placeholder="Унесите Ваше Сугестије"
                onChange={(e) => setFeedback(e.target.value)}
                value={feedback}
              />
              <div>
                <button
                  className="amplify-button amplify-button--primary"
                  onClick={handleSubmitFeedback}>
                  Пошаљи
                </button>
                <button
                  className="amplify-button amplify-button--secondary"
                  onClick={() => setShowFeedbackModal(false)}>
                  Одустани
                </button>
              </div>
            </Modal>
          )}
        </div>
        <div className="powered-by">
          Powered by
          <a
            href="https://www.labsoft.ai"
            target="_blank"
            rel="noreferrer"
            className="labsoft-link">
            <img
              src={`${process.env.PUBLIC_URL}/Logo.png`}
              alt="Logo"
              className="logo-img-pb"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
