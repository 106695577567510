import React from "react";

const AudioPlayer = ({ src }) => {
  return (
    <div>
      <audio controls src={src}>
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
