import axios from "axios";

const downloadTranscript = (transcript, format) => {
  const text = transcript.postprocessed_transcript.S;
  const filename = `transcript_${transcript.taskID.S}.${format}`;

  let blob;
  if (format === "txt") {
    blob = new Blob([text], { type: "text/plain" });
  } else if (format === "docx") {
    // Za docx, potrebna je dodatna logika ili biblioteka za konverziju
    blob = new Blob([text], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  }

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadTranscriptWithID = async (taskID, format) => {
  try {
    const response = await axios.get(
      `https://uh2obs4stk.execute-api.eu-central-1.amazonaws.com/Prod/pollStatus/${taskID}`
    );
    const text = response.data.Items[0].postprocessed_transcript.S;
    const filename = `transcript_${taskID}.${format}`;

    let blob;
    if (format === "txt") {
      blob = new Blob([text], { type: "text/plain" });
    } else if (format === "docx") {
      // Za docx, potrebna je dodatna logika ili biblioteka za konverziju
      blob = new Blob([text], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
    }

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error fetching transcript status:", error);
  }
};

const downloadTranscriptsAsSingleFile = (transcripts, format) => {
  const allTexts = transcripts
    .map((t) => t.postprocessed_transcript.S)
    .join("\n\n");
  const blob = new Blob([allTexts], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `transcripts.${format}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const bulkDownloadSelectedTranscripts = (
  transcripts,
  transcriptIds,
  format,
  mergeFiles
) => {
  if (mergeFiles) {
    const selectedTranscripts = transcripts.filter((t) =>
      transcriptIds.has(t.taskID.S)
    );
    downloadTranscriptsAsSingleFile(selectedTranscripts, format);
  } else {
    transcriptIds.forEach((taskID) => {
      const transcript = transcripts.find((t) => t.taskID.S === taskID);
      if (transcript) {
        downloadTranscript(transcript, format);
      }
    });
  }
};

export {
  downloadTranscript,
  downloadTranscriptWithID,
  bulkDownloadSelectedTranscripts,
};
